import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src74448501/src/kinetic-ui/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "kapp"
    }}>{`Kapp`}</h1>
    <hr />
    <h2 {...{
      "id": "fetchkapps"
    }}>{`fetchKapps`}</h2>
    <p>{`No specific params.`}</p>
    <h3 {...{
      "id": "params"
    }}>{`Params`}</h3>
    <pre><code parentName="pre" {...{}}>{`* kappSlug -> string = (required) slug of the kapp
`}</code></pre>
    <h3 {...{
      "id": "resolves"
    }}>{`Resolves`}</h3>
    <pre><code parentName="pre" {...{}}>{`* kapps -> Kapp[] = an array of kapps
* count -> number = number of kapps matching
* nextPageToken -> string = page token if there are matches beyond the limit
* error -> ErrorResponse{} = an error object on failure
`}</code></pre>
    <h3 {...{
      "id": "example"
    }}>{`Example`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { fetchKapps } from '@kineticdata/react';

fetchKapps().then(({ kapps }) => console.log(kapps));
`}</code></pre>
    <br />
    <hr />
    <h2 {...{
      "id": "fetchkapp"
    }}>{`fetchKapp`}</h2>
    <h3 {...{
      "id": "params-1"
    }}>{`Params`}</h3>
    <pre><code parentName="pre" {...{}}>{`* kappSlug -> string = (required) slug of the kapp
`}</code></pre>
    <h3 {...{
      "id": "resolves-1"
    }}>{`Resolves`}</h3>
    <pre><code parentName="pre" {...{}}>{`* kapp -> Kapp{} = a kapp object
* error -> ErrorResponse{} = an error object on failure
`}</code></pre>
    <h3 {...{
      "id": "example-1"
    }}>{`Example`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { fetchKapp } from '@kineticdata/react';

fetchKapp({ kappSlug }).then(({ kapp }) => console.log(kapp));
`}</code></pre>
    <br />
    <hr />
    <h2 {...{
      "id": "updatekapp"
    }}>{`updateKapp`}</h2>
    <h3 {...{
      "id": "params-2"
    }}>{`Params`}</h3>
    <pre><code parentName="pre" {...{}}>{`* kappSlug -> string = (required) slug of the kapp
* kapp -> Kapp{} = (required) the updated kapp data to send
`}</code></pre>
    <h3 {...{
      "id": "resolves-2"
    }}>{`Resolves`}</h3>
    <pre><code parentName="pre" {...{}}>{`* kapp -> Kapp{} = the updated kapp object
* error -> ErrorResponse{} = an error object on failure
`}</code></pre>
    <h3 {...{
      "id": "example-2"
    }}>{`Example`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { updateKapp } from '@kineticdata/react';

updateKapp({ kappSlug, kapp: kappWithChanges }).then(({ kapp }) =>
  console.log(kapp),
);
`}</code></pre>
    <br />
    <hr />
    <h2 {...{
      "id": "createkapp"
    }}>{`createKapp`}</h2>
    <h3 {...{
      "id": "params-3"
    }}>{`Params`}</h3>
    <pre><code parentName="pre" {...{}}>{`* kapp -> Kapp{} = (required) the kapp data to send
`}</code></pre>
    <h3 {...{
      "id": "resolves-3"
    }}>{`Resolves`}</h3>
    <pre><code parentName="pre" {...{}}>{`* kapp -> Kapp{} = the created form object
* error -> ErrorResponse{} = an error object on failure
`}</code></pre>
    <h3 {...{
      "id": "example-3"
    }}>{`Example`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { createKapp } from '@kineticdata/react';

createKapp({ kapp }).then(({ kapp }) => console.log(kapp));
`}</code></pre>
    <br />
    <hr />
    <h2 {...{
      "id": "deletekapp"
    }}>{`deleteKapp`}</h2>
    <h3 {...{
      "id": "params-4"
    }}>{`Params`}</h3>
    <pre><code parentName="pre" {...{}}>{`* kappSlug -> string = (required) slug of the kapp
`}</code></pre>
    <h3 {...{
      "id": "resolves-4"
    }}>{`Resolves`}</h3>
    <pre><code parentName="pre" {...{}}>{`* kapp -> Kapp{} = the deleted kapp object
* error -> ErrorResponse{} = an error object on failure
`}</code></pre>
    <h3 {...{
      "id": "example-4"
    }}>{`Example`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { deleteKapp } from '@kineticdata/react';

deleteKapp({ kappSlug }).then(({ kapp }) => console.log(kapp));
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      